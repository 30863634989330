import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { Perfil } from '../models/perfil';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  obtenerPerfiles(idAsamblea: number) {
    return this.http.get(this.authService.url + `/perfil/perfilreunion/` + idAsamblea + `/asamblea`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Perfil>;
  }

}
