import { Component, Input, OnInit } from '@angular/core';
import { TipoDocumento } from 'src/app/models/tipo-documento';
import { ModalController } from '@ionic/angular';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { Identificador } from 'src/app/models/identificador';
import * as moment from 'moment';
import { TiposService } from 'src/app/services/tipos.service';
import { ValidarUsuario } from '../../models/validar-usuario';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ParticipanteService } from 'src/app/services/participante.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-validacion-editar-participante',
  templateUrl: './validacion-editar-participante.page.html',
  styleUrls: ['./validacion-editar-participante.page.scss'],
})
export class ValidacionEditarParticipantePage implements OnInit {

  @Input() identificador: Identificador;

  createAutocorrectDateMask: any = createAutoCorrectedDatePipe('dd/mm/yyyy');
  maskDate: any = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  idTipoDocumento: number;
  tipoDocumento: string;
  nombre: string;
  apellido: string;
  numero: string;
  fechaNacimiento: string;
  tiposDocumentos: Array<TipoDocumento> = [];

  loadingFrente = false;
  loadingDorso = false;


  constructor(private modalCtrl: ModalController, private tiposService: TiposService, private participanteService: ParticipanteService,
              private fileService: FileService) { }

  ngOnInit() {
    this.cargarIdentidad();
  }

  cargarIdentidad() {

    this.idTipoDocumento = this.identificador.tipoDocumentoIdentificadorId;
    this.tipoDocumento = this.identificador.tipoDocumentoIdentificadorDescripcion;
    this.nombre = this.identificador.nombreIdentificador;
    this.apellido = this.identificador.apellidoIdentificador;
    this.numero = this.identificador.numeroIdentificador;
    this.fechaNacimiento = moment(this.identificador.fechaNacimientoIdentificador).utcOffset(0, false).format('DD/MM/YYYY');


    this.tiposService.obtenerTiposDocumentoByPais(this.identificador.participanteDTO.idPais).subscribe(
      tipos => {
        this.tiposDocumentos = tipos;
      }
    )

    this.mostrarFrente();
    this.mostrarDorso();

  }

  cerrar() {
    this.modalCtrl.dismiss();
  }

  aceptar() {

    let identificador = new Identificador();

    identificador.idPersonaIdentificador = this.identificador.idPersonaIdentificador;
    identificador.tipoDocumentoIdentificadorId = this.idTipoDocumento
    identificador.numeroIdentificador = this.numero;
    identificador.apellidoIdentificador = this.apellido;
    identificador.nombreIdentificador = this.nombre;
    identificador.fechaNacimientoIdentificador = new Date(moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00.000Z'));

    this.participanteService.editarParticipanteIdentificador(this.identificador.idPersonaIdentificador, identificador).subscribe(
      usuarioValidado => {
        console.log(usuarioValidado);
        this.modalCtrl.dismiss();
      }, 
      err => {
        console.log(err);
      }
    )

  }

  mostrarFrente() {

    this.loadingFrente = true;

    let file = this.identificador.frente;

    console.log('frente identificador', this.identificador);
    if (file == null) {
      console.log('frente vacio');
      this.loadingFrente = false;
      document.getElementById('idEditarFrente').setAttribute('src', 'assets/img/img-no-id.svg');
      return;
    } else {
      this.fileService.obtenerArchivo(file).subscribe(
        (response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let img = document.createElement('img');

          img.src = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          /*if (file.nombreArchivo) {
            downloadLink.setAttribute('download', file.nombre + '.' + file.formato);
          }*/
          console.log('idEditarFrente', document.getElementById('idFrente'));
          console.log('idEditarFrente',  img.src);
          this.loadingFrente = false;
          document.getElementById('idEditarFrente').setAttribute('src', img.src);
          
        }
      ),
      err => {
        console.log(err);
        this.loadingFrente = false;
        document.getElementById('idEditarFrente').setAttribute('src', 'assets/img/img-no-id.svg');
      };
    }
  }

  mostrarDorso() {

    this.loadingDorso = true;

    let file = this.identificador.dorso;

    if (file == null) {
      console.log('dorso vacio');
      document.getElementById('idEditarDorso').setAttribute('src', 'assets/img/img-no-id.svg');
      this.loadingDorso = false;
      return;
    } else {
      this.fileService.obtenerArchivo(file).subscribe(
        (response: any) => {
          let dataType = response.type;
          let binaryDataDorso = [];
          binaryDataDorso.push(response);
          let img = document.createElement('img');
  
          img.src = window.URL.createObjectURL(new Blob(binaryDataDorso, {type: dataType}));
          /*if (file.nombreArchivo) {
            downloadLink.setAttribute('download', file.nombre + '.' + file.formato);
          }*/
          document.getElementById('idEditarDorso').setAttribute('src', img.src);
          this.loadingDorso = false;
        },
        err => {
          console.log(err);
          document.getElementById('idEditarDorso').setAttribute('src', 'assets/img/img-no-id.svg');
          this.loadingFrente = false;
        }
      );
    }

  }  

}
