import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { AccionCirculacion } from '../models/accion-circulacion';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccionesCirculacionService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  grabarAccionCirculacion(idAsamblea: number, accionCirculacion: AccionCirculacion): Observable<AccionCirculacion> {
    return this.http.post(this.authService.url + `/asamblea/acciones/` + idAsamblea, accionCirculacion,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<AccionCirculacion>;
  }

  obtenerAccionesCirculacion(idAsamblea: number) {
    return this.http.get(this.authService.url + `/asamblea/acciones/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Array<AccionCirculacion>>;
  }

  eliminarAccionCirculacion(idAccionCirculacion: number) {
    return this.http.delete(this.authService.url + `/asamblea/acciones/` + idAccionCirculacion,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }
}
