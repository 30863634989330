import { Participante } from 'src/app/models/participante';
import { Documentacion } from 'src/app/models/documentacion';
import { Estado } from './estado';

export class Identificador {
    idPersonaIdentificador: number;
    apellidoIdentificador: string;
	nombreIdentificador: string;
	numeroIdentificador: string;
	tipoDocumentoIdentificadorId: number;
	tipoDocumentoIdentificadorDescripcion: string;
	fechaNacimientoIdentificador: Date;
	sexoIdentificador: string;
    numeroTramiteIdentificador: string;
    
    estados: Estado[];
    cantidadRechazos: number;

    participanteDTO: Participante;
    frente: Documentacion;
    dorso: Documentacion;
    validado: boolean;
    estadoId: number;
    estadoFecha: Date;
    estadoDescripcion: string; 
}
