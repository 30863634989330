export class Configuracion {
    id: number;
    app: number;
    idioma: number;
    nombre: string;
    tipo: number;
    valor: any;
}

const STRING = 1;
const NUMBER = 2;
const BOOLEAN = 3;

const APP_CONF = 1;
const APP_ASAMBLEA = 2;

const LANG_ES = 1;
