import { Component, OnInit, Input, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.page.html',
  styleUrls: ['./modal-error.page.scss'],
})
export class ModalErrorPage implements OnInit {

  @Input() titulo: string;
  @Input() subtitulo: string;
  @Input() mensaje: string;

  mostrarErrorSistema: boolean;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  aceptar() {
    return this.modalCtrl.dismiss();
  }

  async present(titulo: string, subtitulo: string, mensaje: string) {

    if (mensaje == null) {
      this.mostrarErrorSistema = true;
    } else if (mensaje == '') {
      this.mostrarErrorSistema = true;
    } else if (mensaje.substring(0, 14) == 'org.hibernate.') {
      this.mostrarErrorSistema = true;
      console.log(mensaje);
    } else {
      this.mostrarErrorSistema = false;
    }

    const modal = await this.modalCtrl.create({
      component: ModalErrorPage,
      cssClass: 'asamblear-modal-class',
      componentProps: {
        titulo,
        subtitulo,
        mensaje
      }
    });

    if ((mensaje == null) || (mensaje?.substring(0, 14) == 'org.hibernate.')) {

    } else {

    }

    await modal.present();
  }

  
}
