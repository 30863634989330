import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { OpcionVotacion } from '../../models/opcion-votacion';
import { VotacionService } from '../../services/votacion.service';
import { AlertController, ModalController } from '@ionic/angular';
import { AsambleaService } from '../../services/asamblea.service';
import { ParticipanteService } from '../../services/participante.service';
import { ModalErrorPage } from '../core/modal-error/modal-error.page';
import { BaseCalculo } from 'src/app/models/base-calculo';
import { OrdenDiaService } from 'src/app/services/orden-dia.service';
import { OrdenDia } from 'src/app/models/ordenDia';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Convocado } from 'src/app/models/convocado';
import { Votacion } from 'src/app/models/votacion';
import { Mensaje } from 'src/app/models/mensaje';
import { AccionCirculacion } from 'src/app/models/accion-circulacion';
import { AccionesCirculacionService } from 'src/app/services/acciones-circulacion.service';


@Component({
  selector: 'app-votacion-editar',
  templateUrl: './votacion-editar.page.html',
  styleUrls: ['./votacion-editar.page.scss'],
})
export class VotacionEditarPage implements OnInit {

  @ViewChild('noVotaComponent') noVotaComponent: IonicSelectableComponent;
  @ViewChild('requeridoComponent') requeridoComponent: IonicSelectableComponent;
  @Input() votacion: Votacion;


  private currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 3,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });

  constructor(private votacionService: VotacionService, public asambleaService: AsambleaService,
              private alertController: AlertController, public participanteService: ParticipanteService,
              private modalError: ModalErrorPage, private ordenDiaService: OrdenDiaService,
              private modalCtrl: ModalController, private accionesCirculacionService: AccionesCirculacionService
              ) { }

  id: number;
  ordenDiaTitulo: string;
  titulo: string;
  tituloInvalido: boolean;
  pregunta: string;
  preguntaInvalido: boolean;
  idTipoVotacion: number;
  idTipoVotacionInvalido: boolean;
  orden: number;
  ordenInvalido: boolean;
  idQuorum: number;
  idQuorumInvalido: boolean;
  quorumEspecial: string;
  quorumEspecialInvalido: boolean;
  votoAcumulativo: boolean;
  votoSecreto: boolean;
  ocultarResultadosParciales: boolean;
  idEstado: number;

  idOrdenDia: number;
  idOrdenDiaInvalido: boolean;
  ordenDiaDescripcion: string;
  idMayoriasEspeciales: number;
  mayoriasEspeciales: string;
  idMayoriasEspecialesInvalido: boolean;
  mayoriaEspecialDescripcion: string;
  mayoriaEspecialDescripcionInvalido: boolean;

  idBaseCalculo: number;
  baseCalculo: string;
  votoMultiple: boolean;
  votoMultipleCantidadOpciones: number;
  votoMultipleCantidadOpcionesInvalido: boolean;
  excluirMoroso: boolean;
  anulaVotoMultiple: boolean;
  claseAcciones = false;
  acciones: Array<AccionCirculacion>;
  idAcciones: number;
  
  opcionDescripcion: string;
  opcionDescripcionInvalido: boolean;
  listaOpciones: Array<OpcionVotacion> = [];
  listaOrdenDia: Array<OrdenDia> = [];

  listaBaseCalculo: Array<BaseCalculo> = [];

  participantesAsamblea = null;
  participanteNoVota: Array<Convocado> =  [];
  idParticipanteInvalido: boolean;
  listaParticipantes: Array<Convocado> = [];
  listaParticipantesReal: Array<Convocado> = [];
  participanteExiste: boolean;

  participantesAsambleaRequerido = null;
  participanteRequerido: Array<Convocado> =  [];
  idParticipanteRequeridoInvalido: boolean;
  listaParticipantesRequerido: Array<Convocado> = [];
  participanteRequeridoExiste: boolean;

  listaVotaciones: Array<Votacion> = [];

  okGrabarVotacion = 0;
  tmrOkGrabarVotacion: any;

  okOpcionAgreagada = 0;
  tmrOkOpcionAgreagada: any;

  okNoVotaAgregado = 0;
  tmrOkNoVotaAgregado: any;

  okRequeridoAgregado = 0;
  tmrRequeridoAgregado: any;

  ngOnInit() {

    this.cargarListaBaseCalculo();
    this.cargarListaOrdenDia();
    this.cargarAcciones();

    this.ordenDiaTitulo = this.votacion.temarioTitulo;
    this.titulo = this.votacion.titulo;
    this.pregunta = this.votacion.pregunta;
    this.idQuorum = this.votacion.idQuorum;
    this.quorumEspecial = this.votacion.quorumEspecial;
    this.idMayoriasEspeciales = this.votacion.idMayoriasEspeciales;
    this.mayoriasEspeciales = this.votacion.mayoriasEspeciales;
    this.votoSecreto = this.votacion.secreto;
    this.votoMultiple = this.votacion.votoMultiple;
    this.votoMultipleCantidadOpciones = this.votacion.votoMultipleCantidadOpciones;
    this.idBaseCalculo = this.votacion.idBaseCalculo;

    this.listaOpciones = this.votacion.opcionesDTO;
    this.listaParticipantes = this.votacion.noVotan;
    this.listaParticipantesRequerido = this.votacion.requeridos;
    this.excluirMoroso = this.votacion.excluirMoroso;
    this.ocultarResultadosParciales = this.votacion.ocultarResultadosParciales;
    this.idAcciones = this.votacion?.accionesDTO?.id;
    this.claseAcciones = this.votacion.claseAcciones;
    this.anulaVotoMultiple = this.votacion.anulaVotoMultiple;

  }

  guardarVotacion() {

    let votacion = new Votacion();

    votacion = this.votacion;

    votacion.titulo = this.titulo;

    votacion.idQuorum = this.idQuorum;
    votacion.quorumEspecial = this.quorumEspecial;

    votacion.idMayoriasEspeciales = this.idMayoriasEspeciales;
    votacion.mayoriaEspecialDescripcion = this.mayoriaEspecialDescripcion;
    votacion.idBaseCalculo = this.idBaseCalculo;

    votacion.permiteVotoAcumulativo = this.votoAcumulativo;

    if (this.votoMultiple == null || this.votoMultiple == false) {
      votacion.votoMultiple = false;
      votacion.votoMultipleCantidadOpciones = 1;  
    } else {
      votacion.votoMultiple = this.votoMultiple;
      votacion.votoMultipleCantidadOpciones = this.votoMultipleCantidadOpciones;
    }

    votacion.secreto = this.votoSecreto;

    if (this.excluirMoroso == null) {
      votacion.excluirMoroso = true;
    } else {
      votacion.excluirMoroso = this.excluirMoroso;
    }

    if (this.ocultarResultadosParciales == null) {
      votacion.ocultarResultadosParciales = true;
    } else {
      votacion.ocultarResultadosParciales = this.ocultarResultadosParciales;
    }

    if (this.anulaVotoMultiple == null) {
      votacion.anulaVotoMultiple = false;
    } else {
      votacion.anulaVotoMultiple = this.anulaVotoMultiple;
    }

    if (this.claseAcciones == null) {
      votacion.claseAcciones = false;
    } else {
      votacion.claseAcciones = this.claseAcciones;
    }

    if (votacion.claseAcciones) {
      votacion.accionesDTO = new AccionCirculacion();
      votacion.accionesDTO.id = this.idAcciones;
    }


    votacion.noVotan = this.listaParticipantes;
    votacion.requeridos = this.listaParticipantesRequerido;

    if (!this.validaVotacion()) {
      return;
    }
    console.log('Votacion grabar', votacion);
    this.votacionService.grabarVotacion(this.asambleaService.asamblea.id, votacion).subscribe(
      async (accion) => {

        this.modalCtrl.dismiss(votacion);

      },
      async (error) => {
        this.modalError.present('Votación', 'Grabar', error.error.mensajeError)
      }
    );

  }

  copiarOrdenDiaTitulo() {
    this.listaOrdenDia.forEach(orden => {
      if (orden.id == this.idOrdenDia) {
        this.titulo = orden.titulo;
      }
    });
  }

  agregarOpcion() {

    if (!this.validaOpcion()) {
      return;
    }

    const opcion = new OpcionVotacion();

    opcion.opcion = this.opcionDescripcion;
    opcion.eliminar = false;

    this.votacionService.agregarOpcionVotacion(this.votacion.id, opcion).subscribe(
      votacion => {
        this.votacion.opcionesDTO = votacion.opcionesDTO;
        this.listaOpciones = votacion.opcionesDTO;

        this.okOpcionAgreagada++;

        this.tmrOkOpcionAgreagada = setInterval(() => {
          this.okOpcionAgreagada--;
          if (this.okOpcionAgreagada <= 0) {
            clearInterval(this.tmrOkOpcionAgreagada);
          }
        }, 4000);

      },
      err => {
        console.log(err);
      }
    );

    this.opcionDescripcion = null;
  }

  eliminarOpcion(item: OpcionVotacion) {
    this.votacionService.eliminarOpcionVotacion(item.id).subscribe(
      votacion => {

        this.votacion.opcionesDTO.splice(this.votacion.opcionesDTO.indexOf(item), 1);
        this.listaOpciones = this.votacion.opcionesDTO;

        this.okOpcionAgreagada++;

        this.tmrOkOpcionAgreagada = setInterval(() => {
          this.okOpcionAgreagada--;
          if (this.okOpcionAgreagada <= 0) {
            clearInterval(this.tmrOkOpcionAgreagada);
          }
        }, 4000);

      },
      async (error) => {
        this.modalError.present('Votación', 'Eliminar opción', error.error.mensajeError)
      }

    )
  }

  async presentAlert(subHeader: string, message: string) {
    const alert = await this.alertController.create({
      header: 'Atencion',
      subHeader,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async cargarParticipantesAsamblea() {

  }

  agregarParticipante() {

    if (!this.validaParticipante()) {
      return;
    }

    let existe = false;
    this.participanteNoVota.forEach(participante => {

      existe = false;
      this.listaParticipantes.forEach(part => {
        if (part.id == participante.id) {
          this.participanteExiste = true;
          existe = true;
          return;
        }
      });
      if (!existe) {
        participante.eliminar = false;
        this.listaParticipantes.push(participante);
        //this.votacion.noVotan.push(participante);

        this.okNoVotaAgregado++;

        this.tmrOkNoVotaAgregado = setInterval(() => {
          this.okNoVotaAgregado--;
          if (this.okNoVotaAgregado <= 0) {
            clearInterval(this.tmrOkNoVotaAgregado);
          }
        }, 4000);
      }
    });

    this.participanteNoVota = null;

  }

  eliminarParticipante(participante: Convocado) {

    if (participante.id == null) {
      this.listaParticipantes.splice(this.listaParticipantes.indexOf(participante), 1);
    } else {
      this.votacion.noVotan.forEach(opcion => {
        if (opcion.id == participante.id) {
          opcion.eliminar = true;
        }
      });
    }
  }

  agregarParticipanteRequerido() {

    if (!this.validaParticipanteRequerido()) {
      return;
    }

    let existe = false;
    this.participanteRequerido.forEach(participante => {

      existe = false;
      this.listaParticipantesRequerido.forEach(part => {
        if (part.id == participante.id) {
          this.participanteRequeridoExiste = true;
          existe = true;
          return;
        }
      });
      if (!existe) {
        participante.eliminar = false;
        this.listaParticipantesRequerido.push(participante);

        this.participanteRequerido = null;

        this.okRequeridoAgregado++;

        this.tmrRequeridoAgregado = setInterval(() => {
          this.okNoVotaAgregado--;
          if (this.okRequeridoAgregado <= 0) {
            clearInterval(this.tmrRequeridoAgregado);
          }
        }, 4000);
      }
    });

    this.participanteNoVota = null;

  }

  eliminarParticipanteRequerido(participante: Convocado) {

    if (participante.id == null) {
      this.listaParticipantesRequerido.splice(this.listaParticipantesRequerido.indexOf(participante), 1);
    } else {
      this.votacion.requeridos.forEach(opcion => {
        if (opcion.id == participante.id) {
          opcion.eliminar = true;
        }
      });
    }
  }


  validaVotacion() {
    this.tituloInvalido = (this.titulo == null || this.titulo == '');
    if (this.confValor('votacion.requiereQuorum')) {
      this.idQuorumInvalido = (this.idQuorum == null);
      this.quorumEspecialInvalido = (this.idQuorum == 4 && (this.quorumEspecial == '' || this.quorumEspecial == null));
    }
    if (this.confValor('votacion.requiereMayoriasEspeciales')) {
      this.idMayoriasEspecialesInvalido = (this.idMayoriasEspeciales == null);
      this.mayoriaEspecialDescripcionInvalido = (this.idMayoriasEspeciales == 4 &&
        (this.mayoriaEspecialDescripcion == '' || this.mayoriaEspecialDescripcion == null));
    }

    return !(this.tituloInvalido || this.idQuorumInvalido || this.quorumEspecialInvalido ||
      this.idOrdenDiaInvalido || this.idMayoriasEspecialesInvalido || this.mayoriaEspecialDescripcionInvalido);
  }

  validaOpcion() {
    this.opcionDescripcionInvalido = (this.opcionDescripcion == null || this.opcionDescripcion == '');
    return !this.opcionDescripcionInvalido;
  }

  validaParticipante() {
    this.idParticipanteInvalido = (this.participanteNoVota == null);
    return !this.idParticipanteInvalido;
  }

  validaParticipanteRequerido() {
    this.idParticipanteInvalido = (this.participanteRequerido == null);
    return !this.idParticipanteRequeridoInvalido;
  }

  cargarListaBaseCalculo() {
    this.votacionService.baseCalculo().subscribe(
      lista => {
        this.listaBaseCalculo = lista;
      },
      err => {
        console.log(err.error.mensajeError);
      }
    );
  }

  cargarListaOrdenDia() {
    this.ordenDiaService.obtenerOrdenDia(this.asambleaService.asamblea.id).subscribe(
      lista => {
        this.listaOrdenDia = lista;
      },
      err => {
        console.log(err.error.mensajeError);
      }
    );
  }

  confValor(nombre: string) {
    return this.asambleaService.getConfiguracionValue(nombre)?.valor;
  }

  cambiaParticipante(event) {
    console.log(event);
  }

  searchParticipante(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    console.log(event);
    if (event.text.trim().length < 3) {
      this.participantesAsamblea = [];
    } else {
      this.participanteService.buscarParticipantesPorNombreNumero(this.asambleaService.asamblea.id, event.text.trim()).subscribe(
        async participantes => {
          this.participantesAsamblea = participantes;
        }
      );
    }
  }

  cambiaParticipanteRequerido(event) {
    console.log(event);
  }

  searchParticipanteRequerido(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    console.log(event);
    if (event.text.trim().length < 3) {
      this.participantesAsambleaRequerido = [];
    } else {
      this.participanteService.buscarParticipantesPorNombreNumero(this.asambleaService.asamblea.id, event.text.trim()).subscribe(
        async participantes => {
          this.participantesAsambleaRequerido = participantes;
        }
      );
    }
  }


  limpiarQuorumEspecial() {
    this.quorumEspecial = null;
  }

  limpiarMayoriaEspecialDescripcion() {
    this.mayoriaEspecialDescripcion = null;
  }

  confirm() {
    this.noVotaComponent.confirm();
    this.noVotaComponent.close();
  }

  confirmRequerido() {
    this.requeridoComponent.confirm();
    this.requeridoComponent.close();
  }

  cargarAcciones() {
    this.accionesCirculacionService.obtenerAccionesCirculacion(this.asambleaService.asamblea.id).subscribe(
      async (data) => {
        this.acciones = data;
      },
      async (error) => {
        console.error(error);
      }
    );
  }

}
