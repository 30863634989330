import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{
  constructor(private router: Router, private auth: AuthService, private alertCtrl: AlertController) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.auth.token) {
      return of(true);
    } else {
      this.alertCtrl.create({
        header: 'Unauthorized',
        message: 'You are not allowed to access that page.',
        buttons: ['OK']
      }).then(alert => alert.present());

      return of(false);
    }
  }
}
