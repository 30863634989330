import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Plugins } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Token } from '../models/token';
import { Login } from 'src/app/models/login';

const helper = new JwtHelperService();
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public url = environment.url;
  public token = '';
  public decodedToken = new BehaviorSubject(null);
  public token_key = environment.token_key;
  public usuario: Observable<any>;

  constructor(private http: HttpClient, private plt: Platform, private router: Router) {
    this.getToken();
   }

  getToken() {
    const platformObs = from(this.plt.ready());

    Storage.get({key: this.token_key}).then(token => {
      if (token) {
        this.token = token.value;
        this.decodedToken = helper.decodeToken(token.value);

        if (this.token == null) {
          this.router.navigate(['login']);
        }

        return this.token;
      } else {
        return null;
      }
    });
  }

  login(credentials: Login): Observable<boolean> {
    return this.http.post(this.url + `/usuarios/login/administrador`, credentials,
    { headers: {'Content-Type': 'application/json'}}).pipe(
      take(1),
      map((res: Token) => {
        return res.access_token;
      }),
      switchMap(token => {
        this.token = token;
        const decoded = helper.decodeToken(token);
        this.decodedToken = decoded;

        const storageObs = Storage.set({key: this.token_key, value: token});
        return of(true);
      })
    ) as Observable<boolean>;
  }

  getUser() {
    return this.decodedToken;
  }

  logout() {
    Storage.remove({key: this.token_key}).then(() => {
      this.decodedToken = null;
      this.token = null;
    });
  }
}
