import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AsambleaService } from 'src/app/services/asamblea.service';
import { Participante } from 'src/app/models/participante';
import { ModalController, LoadingController } from '@ionic/angular';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CategoriaSocio } from 'src/app/models/categoria-socio';
import { AccionConvocado } from 'src/app/models/accion-convocado';
import { TipoDocumento } from 'src/app/models/tipo-documento';
import { PerfilDefault } from '../../models/perfil-default';
import { PerfilService } from 'src/app/services/perfil.service';
import { TiposService } from 'src/app/services/tipos.service';
import * as moment from 'moment';
import { Convocado } from 'src/app/models/convocado';
import { ParticipanteService } from 'src/app/services/participante.service';
import { ModalErrorPage } from '../core/modal-error/modal-error.page';
import { AccionesCirculacionService } from '../../services/acciones-circulacion.service';
import { CategoriaSocioService } from 'src/app/services/categoria-socio.service';
import { AccionCirculacion } from 'src/app/models/accion-circulacion';
import { Asamblea } from 'src/app/models/asamblea';
import { EstructuraUnidades } from 'src/app/models/estructura-unidades';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Buscar } from 'src/app/models/buscar';
import { EstructuraUnidadService } from 'src/app/services/estructura-unidad.service';

@Component({
  selector: 'app-convocados-editar',
  templateUrl: './convocados-editar.page.html',
  styleUrls: ['./convocados-editar.page.scss'],
})
export class ConvocadosEditarPage implements OnInit {

  @ViewChild('ufComponent') ufComponent: IonicSelectableComponent;

  private dniMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 8,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });

  private cedulaPanamaMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 8,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });  

  constructor(private asambleaService: AsambleaService, private modalCtrl: ModalController,
              private perfilService: PerfilService, private tiposService: TiposService,
              private participanteService: ParticipanteService, private modalError: ModalErrorPage,
              private accionesCirculacionService: AccionesCirculacionService, private categoriaSocioService: CategoriaSocioService,
              private estructuraUnidadService: EstructuraUnidadService, private loadingCtrl: LoadingController) { }

  id: number;
  idTipoDocumento: number;
  idTipoDocumentoInvalido: boolean;
  numero: string;
  numeroInvalido: boolean;
  idTipoDNIInvalido: boolean;
  nombre: string;
  nombreInvalido: boolean;
  domicilio: string;
  domicilioInvalido: boolean;
  email: string;
  emailInvalido: boolean;
  idPerfil: number;
  idPerfilInvalido: boolean;
  moderador: boolean;
  orador: boolean;
  voz: boolean;
  voto: boolean;
  sonidoVideo: boolean;
  documentacion: boolean;
  personeria: boolean;

  tieneParticipante: boolean;

  fechaSuscripcion: string;
  fechaSuscripcionInvalido: boolean;
  idAcciones: number;
  idAccionesInvalido: boolean;
  cantidad: number;
  cantidadInvalido: boolean;
  valorIntegrado: number;
  valorIntegradoInvalido: boolean;

  tipoDocumentos: Array<TipoDocumento> = [];
  perfiles = null;
  atributosPerfil: PerfilDefault;
  acciones = null;
  accionesConvocados: Array<AccionConvocado> = [];

  idCategoria: number;
  idCategoriaInvalido: boolean;
  categorias: Array<CategoriaSocio> = [];

  convocadoTotalVotos = 0;
  convocadoTotalCapital = 0;
  convocadoTotalCapitalIntegrado = 0;

  totalVotosAsamblea = 0;
  totalAccionesAsamblea = 0;
  totalConvocados = 0;
  totalAcciones = 0;
  totalVotos = 0;

  okGuardado = 0;
  tmrOkGuardado: any;

  okAccionesAgregadas = 0;
  tmrOkAccionesAgregadas: any;

  maskTipoDocumento: any;
  placeHolderTipoDocumento: string;

  textoBuscar: string;

  tmpFile: File;
  tmpFileInvalido = false;
  mostrarAddAcciones: boolean;

  unidadesFuncionales = null;
  unidadFuncional: Array<EstructuraUnidades> =  [];
  idUfInvalido: boolean;
  listaUf: Array<EstructuraUnidades> = [];
  listaUfFinal: Array<EstructuraUnidades> = [];
  ufExiste: boolean;

  okUfAgregado = 0;
  tmrUfAgregado: any;

  @Input() pConvocado: Convocado;
  @Input() asamblea: Asamblea;

  convocado: Convocado;

  async ngOnInit() {

    const loading = this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Procesando ...'
    });

    (await loading).present();

    if (this.confValor('convocados.accionesPosee')) {
      this.cargarAcciones();
    }

    if (this.confValor('convocados.socioCategoria')) {
      this.cargarCategorias();
    }

    this.participanteService.obtenerConvocadoParaEditar(this.pConvocado.id).subscribe(
      async conv => {
        this.convocado = conv;

        console.log(conv);

/*        this.idTipoDocumento = this.convocado.idTipoDocumento;*/

        this.tiposService.obtenerTiposDocumento(this.asamblea.id).subscribe(
          tipos => {
            this.tipoDocumentos = tipos;
            this.idTipoDocumento = this.convocado.idTipoDocumento;
            this.changeTipoDoc();            
            this.numero = this.tiposService.cleanTipoDocMask(this.convocado.idTipoDocumento, this.convocado.numero);
          },
          async err => {
            (await loading).dismiss();
          }
        );
    
        this.nombre = this.convocado.nombre;
        this.domicilio = this.convocado.domicilio;
        this.email = this.convocado.email;
        this.idPerfil = this.convocado.idPerfil;

        this.perfilService.obtenerPerfiles(this.asambleaService.asamblea.id).subscribe(
          async perfiles => {
            this.perfiles = perfiles;
            this.cargarAtributosPerfil();
          },
          async err => {
            (await loading).dismiss();
          }
        );
    
        this.idCategoria = this.convocado.categoriaSocioDTO.id;
        this.accionesConvocados = this.convocado.accionesDTO;
        this.listaUf = this.convocado.estructuraUnidadesDTO;
        this.listaUfFinal = this.convocado.estructuraUnidadesDTO;
    
        this.participanteService
        this.moderador = this.convocado.moderador;
        this.orador = this.convocado.orador;
        this.voz = this.convocado.voz;
        this.voto = this.convocado.voto;
        this.sonidoVideo = this.convocado.sonidoVideo;
        this.documentacion = this.convocado.documentacion;
        this.personeria = this.convocado.personeria;
        this.tieneParticipante = (this.convocado.idAsambleaParticipante != null);
    
        this.convocadoTotalVotos = 0;
        this.convocadoTotalCapital = 0;
        this.convocadoTotalCapitalIntegrado = 0;
        this.accionesConvocados.forEach(accion => {
          this.convocadoTotalVotos = this.convocadoTotalVotos + (accion.accionesDTO.votos * accion.cantidad);
          this.convocadoTotalCapital = this.convocadoTotalCapital + (accion.accionesDTO.valor * accion.cantidad);
          this.convocadoTotalCapitalIntegrado = this.convocadoTotalCapitalIntegrado + accion.valorIntegrado;
        });            
        (await loading).dismiss();

      }
    )

  }

  confValor(nombre: string) {
    return this.asambleaService.getConfiguracionValue(nombre)?.valor;
  }

  cerrar() {
    this.modalCtrl.dismiss();
  }


  changeTipoDoc() {

    let car: string;
    this.tipoDocumentos.forEach(tipo => {
      if (tipo.id == this.idTipoDocumento) {
        this.numero = null;
        const mascara = tipo.mascara;
        this.placeHolderTipoDocumento = tipo.placeholder;
        if (mascara != null) {
          if (tipo.id == 1) { // DNI
            this.maskTipoDocumento = this.dniMask;
            } else if (tipo.id == 40) { // cedula panama
              this.maskTipoDocumento = this.cedulaPanamaMask;
            } else {
            this.maskTipoDocumento = [];

            for (let i = 0; i < mascara.length ; i++) {
              car = mascara.substring(i, i+1);
              if (mascara.substring(i, i+1) == '0') {
                this.maskTipoDocumento[i] = /\d/;
              } else {
                this.maskTipoDocumento.push(car);
              }
            }
          }
        } else {
          this.maskTipoDocumento = null;
        }

      }
    });
  }

  grabarConvocado() {

    if (!this.validarConvocado()) {
      return;
    }

    const convocado = new Convocado();

    convocado.id = this.convocado.id;
    convocado.idTipoDocumento = this.idTipoDocumento;
    convocado.numero = this.tiposService.cleanTipoDocMask(this.idTipoDocumento, this.numero);
    convocado.nombre = this.nombre;
    convocado.domicilio = this.domicilio;
    convocado.email = this.email;
/*    convocado.idPerfil = this.idPerfil;
    convocado.accionesDTO = this.accionesConvocados;*/
    convocado.categoriaSocioDTO = new CategoriaSocio();
    convocado.categoriaSocioDTO.id = this.idCategoria;

    if (this.moderador == null) {
      convocado.moderador = false;
    } else {
      convocado.moderador = this.moderador;
    }

    if (this.orador == null) {
      convocado.orador = false;
    } else {
      convocado.orador = this.orador;

      if (convocado.orador) {
        this.sonidoVideo = true;
      }
    }

    if (this.voto == null) {
      convocado.voto = false;
    } else {
      convocado.voto = this.voto;
    }

    if (this.voz == null) {
      convocado.voz = false;
      convocado.chat = false;
    } else {
      convocado.voz = this.voz;
      convocado.chat = this.voz; /* voz y chat van juntos */
    }

    if (this.sonidoVideo == null) {
      convocado.sonidoVideo = false;
      convocado.orador = false;
    } else {
      convocado.sonidoVideo = this.sonidoVideo;

      if (convocado.sonidoVideo == false) {
        convocado.orador = false;
      }
    }

    if (this.documentacion == null) {
      convocado.documentacion = false;
    } else {
      convocado.documentacion = this.documentacion;
    }

    if (this.personeria == null) {
      convocado.personeria = false;
    } else {
      convocado.personeria = this.personeria;
    }

    if (this.idPerfil != null) {
      convocado.idPerfilNuevo = this.idPerfil;
    }

    convocado.estructuraUnidadesDTO = this.listaUfFinal;

    convocado.estructuraUnidadesDTO.forEach(uf => {
      if (uf.eliminar == null) {
        uf.eliminar = false;
      }
      
    });

    console.log('CONVOCADO EDITAR', convocado);

    this.participanteService.editarConvocado(this.asambleaService.asamblea.id, convocado).subscribe(
      async (accion) => {

        this.modalCtrl.dismiss();

      },
      async (error) => {
        console.log(error);
        this.modalError.present('Convocado', 'Error', error.error.mensajeError);
      }
    );

  }

  cargarAtributosPerfil() {
    if (this.idPerfil == null) {
      this.atributosPerfil = null;
      this.mostrarAddAcciones = false;
    } else {
      this.perfiles.forEach(perfil => {
        if (perfil.id == this.idPerfil) {
          this.atributosPerfil = perfil.perfilDefaultDTO;
          this.mostrarAddAcciones = perfil.perteneceAsociacion;
        }
      });
    }
  }

  cargarAcciones() {
    this.accionesCirculacionService.obtenerAccionesCirculacion(this.asambleaService.asamblea.id).subscribe(
      async (data) => {
        this.acciones = data;

        this.totalAccionesAsamblea = 0;
        this.totalVotosAsamblea = 0;
        this.acciones.forEach(accion => {
          this.totalAccionesAsamblea = this.totalAccionesAsamblea + accion.cantidad;
          this.totalVotosAsamblea = this.totalVotosAsamblea + (accion.cantidad * accion.votos);
        });
      },
      async (error) => {
        console.error(error);
      }
    );
  }

  cargarCategorias() {
    this.categoriaSocioService.obtenerCategoriaSocio(this.asambleaService.asamblea.id).subscribe(
      lista => {
        this.categorias = lista;
      },
      err => {
        console.log(err);
      }
    );
  }

  agregarAcciones() {

    if (!this.validarAcciones()) {
      return;
    }

    const accionConvocado = new AccionConvocado();

    accionConvocado.fechaSuscripcion = new Date(moment(this.fechaSuscripcion, 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00.000Z'));
    accionConvocado.idAcciones = this.idAcciones;
    accionConvocado.cantidad = this.cantidad;
    accionConvocado.valorIntegrado = this.valorIntegrado;
    accionConvocado.accionesDTO = new AccionCirculacion();

    this.acciones.forEach(accion => {
      if (accion.id == this.idAcciones) {
        accionConvocado.accionesDTO = accion;
      }
    });

    this.accionesConvocados.push(accionConvocado);

    this.convocadoTotalVotos = 0;
    this.convocadoTotalCapital = 0;
    this.accionesConvocados.forEach(accion => {
      this.convocadoTotalVotos = this.convocadoTotalVotos + (accion.accionesDTO.votos * accion.cantidad);
      this.convocadoTotalCapital = this.convocadoTotalCapital + (accion.accionesDTO.valor * accion.cantidad);
      this.convocadoTotalCapitalIntegrado = this.convocadoTotalCapitalIntegrado + accion.valorIntegrado;
    });

    this.okAccionesAgregadas++;

    this.tmrOkAccionesAgregadas = setInterval(() => {
      this.okAccionesAgregadas--;
      if (this.okAccionesAgregadas <= 0) {
        clearInterval(this.tmrOkAccionesAgregadas);
      }
    }, 4000);

    this.cantidad = null;
    this.valorIntegrado = null;
    this.idAcciones = null;
    this.fechaSuscripcion = null;

  }

  fechaFormat(fecha: Date) {
    return moment(fecha).utcOffset(0, false).format('DD/MM/YYYY');
  }

  validarConvocado() {
    this.idTipoDocumentoInvalido = (this.idTipoDocumento == null);
    if (this.placeHolderTipoDocumento != null && this.placeHolderTipoDocumento != '')  {
      this.numeroInvalido = (this.numero == null || this.numero == '' || this.numero.replace('_', '').length < this.numero.length);
    } else {
      this.numeroInvalido = (this.numero == null || this.numero == '');
    }

    this.idTipoDNIInvalido = false;
    if ((!this.numeroInvalido) && this.idTipoDocumento == 1 && this.numero.replace('.', '').length < 6) {
      this.idTipoDNIInvalido = true;
    }

    this.nombreInvalido = (this.nombre == null || this.nombre == '');
    this.domicilioInvalido = (this.domicilio == null || this.domicilio == '');
    this.emailInvalido = (this.email == null || this.email == '');
    this.idPerfilInvalido = (this.idPerfil == null)
    return !(this.idAccionesInvalido || this.numeroInvalido || this.nombreInvalido || this.domicilioInvalido || 
      this.emailInvalido || this.idPerfilInvalido);
  }

  validarAcciones() {
    this.fechaSuscripcionInvalido = (this.fechaSuscripcion == null || this.fechaSuscripcion == '');
    this.idAccionesInvalido = (this.idAcciones == null);console.log(this.idAccionesInvalido);
    this.cantidadInvalido = (this.cantidad == null);
    this.valorIntegradoInvalido = (this.valorIntegrado == null);
    return !(this.fechaSuscripcionInvalido || this.idAccionesInvalido || this.cantidadInvalido || this.valorIntegradoInvalido);
  }  

  cancelar() {
    this.modalCtrl.dismiss();
  }

  eliminarUF(estructuraFuncionalId: number) {

    this.listaUf = [];
    this.listaUfFinal.forEach(uf => {
      if (uf.id == estructuraFuncionalId) {
        if (uf.nuevo) {
          let index = this.listaUfFinal.indexOf(uf);
          this.listaUfFinal.splice(index, 1);
        } else {
          uf.eliminar = true;
        }
        
      }
      if (!uf.eliminar) {
        this.listaUf.push(uf);
      }
    });

  }

  searchUf(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    console.log(event);
    if (event.text.trim().length < 2) {
      this.unidadesFuncionales = [];
    } else {

      const buscar = new Buscar()
      buscar.nombre = event.text.trim();

      this.estructuraUnidadService.buscarEstructuraUnidad(this.asambleaService.asamblea.id, 1, buscar).subscribe(
        async pagina => {
          this.unidadesFuncionales = pagina.estructuras;
        }
      );
    }
  }

  cambiaUf(event) {
    console.log(event);
  }

  confirm() {
    this.ufComponent.confirm();
    this.ufComponent.close();
  }

  agregarUf() {

    if (!this.validaUf()) {
      return;
    }

    let existe = false;
    this.unidadFuncional.forEach(uf => {

      existe = false;
      this.listaUfFinal.forEach(part => {
        if (part.id == uf.id) {
          if (part.eliminar) {
            part.eliminar = false;
          } else {
            this.ufExiste = true;
          }
          existe = true;
          return;
        }
      });
      if (!existe) {
        uf.eliminar = false;
        uf.nuevo = true;
        this.listaUfFinal.push(uf);
      }
    });

    this.listaUf = [];
    this.listaUfFinal.forEach(uf => {
      if (!uf.eliminar) {
        this.listaUf.push(uf);
      }
    });

    this.unidadFuncional = null;

    this.okUfAgregado++;

    this.tmrUfAgregado = setInterval(() => {
      this.okUfAgregado--;
      if (this.okUfAgregado <= 0) {
        clearInterval(this.tmrUfAgregado);
      }
    }, 4000);
  }

  validaUf() {
    this.idUfInvalido = (this.unidadesFuncionales == null);
    return !this.idUfInvalido;
  }

}
