import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Documentacion } from 'src/app/models/documentacion';
import { DocumentacionAsamblea } from 'src/app/models/documentacion-asamblea';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  uploadArchivo( idAsamblea: number, file: File, name: string, idTipoDocumentacion: number) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('nombre', name);
    //formData.append('tipoDocumentacionAsambleaId', idTipoDocumentacion.toString());
    formData.append('tipoDocumentacionAsambleaId', "0");

    return this.http.post(this.authService.url + `/asamblea/` + idAsamblea + `/documentacion`, formData,
        { headers: {enctype: 'multipart/form-data; boundary=WebAppBoundary',
                    Authorization: 'Bearer ' + this.authService.token}}
    );

  }

  obtenerArchivo(file: Documentacion) {
    return this.http.get(this.authService.url + `/archivo/` + file.id,
        {headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'}
    );
  }

  obtenerArchivoPPM(idAsamblea: number) {
    return this.http.get(this.authService.url + `/listado/liquidacionPPM/` + idAsamblea,
        {headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'}
    );
  }

  obtenerArchivos(idAsamblea: number) {
    return this.http.get(this.authService.url + `/asamblea/archivo/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  deleteArchivo(idArchivo: number) {
    return this.http.delete(this.authService.url + `/archivo/` + idArchivo,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  procesarConvocadoCsv(idAsamblea: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    console.log(file);
    console.log(formData);

    return this.http.post(this.authService.url + `/archivo/csv/` + idAsamblea, formData,
    { headers: {enctype: 'multipart/form-data; boundary=WebAppBoundary',
        Authorization: 'Bearer ' + this.authService.token}}
    );
  }

  exportarUnidadesFuncionales(idAsamblea: number) {

    return this.http.get(this.authService.url + `/csv/unidadesFuncionales/asamblea/` + idAsamblea, 
    { headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'});
 
   }

   exportarVotacion(idVotacion: number) {

    return this.http.get(this.authService.url + `/csv/votacion/` + idVotacion, 
    { headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'});
 
   }

   exportarConvocadosAsamblea(idAsamblea: number) {

    return this.http.get(this.authService.url + `/csv/convocados/asamblea/` + idAsamblea, 
    { headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'});
 
   }

}
