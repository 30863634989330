import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { TipoDocumento } from 'src/app/models/tipo-documento';
import { TipoDocumentacion } from 'src/app/models/tipo-documentacion';
import { Sindicatura } from 'src/app/models/sindicatura';
import { ClaseAcciones } from 'src/app/models/clase-acciones';
import { TipoProducto } from '../models/tipo-producto';
import { TipoAsociacion } from 'src/app/models/tipo-asociacion';
import { TipoReunion } from 'src/app/models/tipo-reunion';
import { Pais } from 'src/app/models/pais';
import { RestriccionIngresoAsambleaIniciada } from '../models/restriccion-ingreso-asamblea-iniciada';
import { TipoAsamblea } from 'src/app/models/tipo-asamblea';
import { TipoModalidad } from '../models/tipo-modalidad';

@Injectable({
  providedIn: 'root'
})
export class TiposService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  obtenerTiposDocumento(idAsamblea: number): Observable<TipoDocumento[]> {
    return this.http.get(this.authService.url + `/tipoDocumento/lista/asamblea/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoDocumento[]>;
  }

  obtenerTiposDocumentoByPais(idPais: number): Observable<TipoDocumento[]> {
    return this.http.get(this.authService.url + `/tipoDocumento/lista/pais/` + idPais,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoDocumento[]>;
  }

  obtenerTiposDocumentacionAsamblea(idAsamblea: number): Observable<TipoDocumentacion[]> {
    return this.http.get(this.authService.url + `/tipoDocumentacionAsamblea/lista/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoDocumentacion[]>;
  }

  obtenerTiposDocumentacionConvocado(idAsamblea: number): Observable<TipoDocumentacion[]> {
    return this.http.get(this.authService.url + `/tipoDocumentacionConvocado/lista/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoDocumentacion[]>;
  }

  obtenerClasesAcciones(): Observable<ClaseAcciones[]> {
    return this.http.get(this.authService.url + `/claseAcciones/lista`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<ClaseAcciones[]>;
  }

  obtenerSindicatura(): Observable<Sindicatura[]> {
    return this.http.get(this.authService.url + `/sindicatura/lista/`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Sindicatura[]>;
  }

  obtenerTipoAsociacion(idPais: number): Observable<TipoAsociacion[]> {
    return this.http.get(this.authService.url + `/public/tipoAsociacion/lista/pais/` + idPais,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoAsociacion[]>;
  }

  obtenerTipoReunion(idTipoAsociacion: number): Observable<TipoReunion[]> {
    return this.http.get(this.authService.url + `/public/tipoReunion/byTipoAsociacion/` + idTipoAsociacion,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoReunion[]>;
  }
  
  obtenerTipoProducto(): Observable<TipoProducto[]> {
    return this.http.get(this.authService.url + `/public/tipoProducto/lista`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoProducto[]>;
  }
  
  obtenerPaises(): Observable<Pais[]> {
    return this.http.get(this.authService.url + `/paises/lista`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Pais[]>;
  }

  obtenerRestriccionIngresoAsambleaIniciada(): Observable<RestriccionIngresoAsambleaIniciada[]>{
    return this.http.get(this.authService.url + `/restriccionIngresoAsambleaIniciada/lista`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<RestriccionIngresoAsambleaIniciada[]>;

  }

  obtenerTipoAsamblea() : Observable<TipoAsamblea[]> {
    return this.http.get(this.authService.url + `/tipoAsamblea/lista`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoAsamblea[]>;
  }

  obtenerTiposModalidad(): Observable<TipoModalidad[]> {
    return this.http.get(this.authService.url + `/tipoModalidad/lista`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TipoModalidad[]>;
  }


  /** FIXME */
  cleanTipoDocMask(idTipoDoc: number, texto: string) {
    if (texto == null) {
      return texto;
    }
    if (idTipoDoc == 1) {
      return texto.split('.').join('');
    }
    if (idTipoDoc == 3) {
      return texto.split('-').join('');
    }
    return texto;
  }

  optenerTiposProductos() {
    const tipos = Array<TipoProducto>();

    let tipo = new TipoProducto();

    tipo.id = 1;
    tipo.descripcion = 'Pequeña ONG';
    tipos.push(tipo);
    tipo = new TipoProducto();
    tipo.id = 2;
    tipo.descripcion = 'PYME';
    tipos.push(tipo);
    tipo = new TipoProducto();
    tipo.id = 3;
    tipo.descripcion = 'Empresa';
    tipos.push(tipo);
    tipo = new TipoProducto();
    tipo.id = 4;
    tipo.descripcion = 'ONG';
    tipos.push(tipo);
    tipo = new TipoProducto();
    tipo.id = 5;
    tipo.descripcion = 'Corporativa';
    tipos.push(tipo);
    tipo = new TipoProducto();
    tipo.id = 6;
    tipo.descripcion = 'Gran ONG';
    tipos.push(tipo);

    return tipos;
  }

}
