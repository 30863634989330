import { OpcionVotacion } from './opcion-votacion';
import { Convocado } from 'src/app/models/convocado';
import { AccionCirculacion } from 'src/app/models/accion-circulacion';
export class Votacion {
    id: number;
    titulo: string;
    pregunta: string;
    idTipoVotacion: number;
    tipoVotacion: string;
    permiteVotoAcumulativo: boolean;
    orden: number;
    idQuorum: number;
    quorum: string;
    quorumEspecial: string;
    idEstado: number;
    estado: string;
    idTemario: number;
    temarioTitulo: string;
    temarioDescripcion: string;
    idMayoriasEspeciales: number;
    mayoriasEspeciales: string;
    mayoriaEspecialDescripcion: string;
    idBaseCalculo: number;
    baseCalculo: string;
    anulaVotoMultiple: boolean; /* Solo SA: Todas las acciones valen 1 voto */
    votoMultiple: boolean;
    votoMultipleCantidadOpciones: number;
    secreto: boolean;
    ocultarResultadosParciales: boolean;
    excluirMoroso: boolean;
    claseAcciones: boolean;
    accionesDTO: AccionCirculacion;

    opcionesDTO: Array<OpcionVotacion>;
    noVotan: Array<Convocado>;
    requeridos: Array<Convocado>;
}