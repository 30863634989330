import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { Participante } from '../models/participante';
import { ParticipanteCambioEstado } from '../models/participante-cambio-estado';
import { Convocado } from '../models/convocado';
import { PaginaConvocado } from 'src/app/models/pagina-convocado';
import { Asamblea } from 'src/app/models/asamblea';
import { Buscar } from 'src/app/models/buscar';
import { Documentacion } from 'src/app/models/documentacion';
import { PaginaIdentificador } from '../models/pagina-identificador';
import { EstadoConvocado } from 'src/app/models/estado-convocado';
import { Identificador } from '../models/identificador';
import { Motivo } from 'src/app/models/motivo';
@Injectable({
  providedIn: 'root'
})
export class ParticipanteService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  grabarParticipante(idAsamblea: number, convocado: Convocado): Observable<Participante> {
    return this.http.post(this.authService.url + `/participante/convocado/` + idAsamblea + `/asamblea`, convocado,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Participante>;
  }

  obtenerParticipantes(idAsamblea: number): Observable<Convocado[]> {
    return this.http.get(this.authService.url + `/participante/convocado/` + idAsamblea + `/asamblea`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Convocado[]>;
  }

  buscarParticipantes(idAsamblea: number, idTipoDocumento: number, numero: string): Observable<Convocado[]> {
    return this.http.get(this.authService.url + `/participante/buscar/`+ idTipoDocumento +`/tipoDocumento/` + numero + `/numero/` + idAsamblea + `/asamblea`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Convocado[]>;
  }

  buscarParticipantesPorNombreNumero(idAsamblea: number, nombre: string): Observable<Convocado[]> {
    return this.http.get(this.authService.url + `/participante/convocado/asamblea/` + idAsamblea + `/nombre/` + nombre,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Convocado[]>;
  }

  eliminarParticipante(idParticipante: number) {
    return this.http.delete(this.authService.url + `/participante/convocado/` + idParticipante,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  confirmarParticipante(idParticipante: number, motivo: ParticipanteCambioEstado): Observable<Participante> {
    return this.http.post(this.authService.url + `/participante/convocado/` + idParticipante + `/confirmar`, motivo,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Participante>;
  }

  rechazarParticipante(idParticipante: number, motivo: ParticipanteCambioEstado): Observable<Participante> {
    return this.http.post(this.authService.url + `/participante/convocado/` + idParticipante + `/rechazar`, motivo,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Participante>;
  }

  revertirParticipante(idParticipante: number, motivo: ParticipanteCambioEstado): Observable<Participante> {
    return this.http.post(this.authService.url + `/participante/convocado/` + idParticipante + `/revertir`, motivo,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Participante>;
  }

  paginaConvocados(idAsamblea: number, pagina: number, buscar: Buscar): Observable<PaginaConvocado> {
    return this.http.post(this.authService.url + `/participante/convocado/asamblea/` + idAsamblea + `/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaConvocado>;
  }

  paginaConfirmados(idAsamblea: number, pagina: number, buscar: Buscar): Observable<PaginaConvocado> {
    return this.http.post(this.authService.url + `/participante/convocado/confirmado/asamblea/` + idAsamblea + `/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaConvocado>;
  }

  paginaRechazados(idAsamblea: number, pagina: number, buscar: Buscar): Observable<PaginaConvocado> {
    return this.http.post(this.authService.url + `/participante/convocado/rechazado/asamblea/` + idAsamblea + `/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaConvocado>;
  }

  paginaPendientes(idAsamblea: number, pagina: number, buscar: Buscar): Observable<PaginaConvocado> {
    return this.http.post(this.authService.url + `/participante/convocado/pendiente/asamblea/` + idAsamblea + `/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaConvocado>;
  }

  paginaConvocadosPendientesConfirmadosRechazados(idAsamblea: number, pagina: number, buscar: Buscar): Observable<PaginaConvocado> {
    return this.http.post(this.authService.url + `/participante/convocado/pendienteRechazadoConfirmado/asamblea/`+ idAsamblea + `/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaConvocado>;
  }

  listadoConvocados(idAsamblea: number) {
    return this.http.get(this.authService.url + `/listado/convocados/` + idAsamblea,
        {headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'}
    );
  }

  listadoConvocadosInscriptos(idAsamblea: number) {
    return this.http.get(this.authService.url + `/listado/convocados/inscriptos/` + idAsamblea,
        {headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'}
    );
  }

  instructivoCargaMasiva(idAsamblea: number) {
    return this.http.get(this.authService.url + `/archivo/instructivo/` + idAsamblea,
        {headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'}
    );
  }

  asambleasAdministrador(): Observable<Asamblea[]> {
    return this.http.get(this.authService.url + `/usuario/asamblea/administrador/disponible`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Asamblea[]>;
  }

  obtenerParticipanteLogueo(): Observable<Participante> {
    this.authService.getToken();
    return this.http.get(this.authService.url + `/participante/logueado`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Participante>;
  }

  obtenerConvocadoDocumentacion(idConvocado: number): Observable<Documentacion[]> {
    this.authService.getToken();
    return this.http.get(this.authService.url + `/documentacion/convocado/` + idConvocado,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Documentacion[]>;
  }

  obtenerConvocadoDocumentacionPublica(idConvocado: number): Observable<Documentacion[]> {
    this.authService.getToken();
    return this.http.get(this.authService.url + `/documentacion/convocado/` + idConvocado + '/publico',
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Documentacion[]>;
  }


  editarConvocado(idAsamblea: number, convocado: Convocado): Observable<Convocado> {
    return this.http.post(this.authService.url + `/participante/edit/convocado/asamblea/` + idAsamblea, convocado,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Convocado>;
  }

  obtenerConvocadoParaEditar(idConvocado: number): Observable<Convocado> {
    return this.http.get(this.authService.url + `/participante/edit/convocado/` + idConvocado,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Convocado>;
  }

  editarParticipanteIdentificador(idPersonaIdentificador: number, identificador: Identificador ): Observable<Identificador> {
    return this.http.post(this.authService.url + `/validar2fa/editar/identificador/` + idPersonaIdentificador, identificador,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  validacionPendientes(pagina: number, buscar: Buscar) : Observable<PaginaIdentificador> {
    return this.http.post(this.authService.url + `/validar2fa/pendientes/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaIdentificador>;
  }

  validacionRechazadas(pagina: number, buscar: Buscar) : Observable<PaginaIdentificador> {
    return this.http.post(this.authService.url + `/validar2fa/rechazados/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaIdentificador>;
  }

  validacionAceptados(pagina: number, buscar: Buscar) : Observable<PaginaIdentificador> {
    return this.http.post(this.authService.url + `/validar2fa/aceptados/pagina/` + pagina, buscar,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaIdentificador>;
  }
  
  obtenerEstadosConvocado(idConvocado: number): Observable<EstadoConvocado[]> {
    this.authService.getToken();
    return this.http.get(this.authService.url + `/participante/convocado/` + idConvocado + '/estados',
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<EstadoConvocado[]>;
  }

  aceptarPendiente(idPersonaIdentificador: number): Observable<Identificador> {
    return this.http.post(this.authService.url + `/validar2fa/aceptar/identificador/` + idPersonaIdentificador, null,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  rechazarPendiente(idPersonaIdentificador: number, motivo: Motivo): Observable<Identificador> {
    return this.http.post(this.authService.url + `/validar2fa/rechazar/identificador/` + idPersonaIdentificador, motivo,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  revertirIdentificador(idPersonaIdentificador: number, motivo: Motivo): Observable<Identificador> {
    return this.http.post(this.authService.url + `/validar2fa/revertir/identificador/` + idPersonaIdentificador, motivo,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  obtenerParticipanteIdentificador(idPersonaIdentificador: number): Observable<Identificador> {
    return this.http.get(this.authService.url + `/validar2fa/participanteIdentificador/` + idPersonaIdentificador,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  siguientePendiente(idPersonaIdentificadorActual: number, buscar: Buscar): Observable<Identificador> {
    return this.http.post(this.authService.url + `/validar2fa/siguientePendiente/participanteIdentificador/` + idPersonaIdentificadorActual, buscar,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  siguienteAceptado(idPersonaIdentificadorActual: number, buscar: Buscar): Observable<Identificador> {
    return this.http.post(this.authService.url + `/validar2fa/siguienteAceptado/participanteIdentificador/` + idPersonaIdentificadorActual, buscar,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  siguienteRechazado(idPersonaIdentificadorActual: number, buscar: Buscar): Observable<Identificador> {
    return this.http.post(this.authService.url + `/validar2fa/siguienteRechazado/participanteIdentificador/` + idPersonaIdentificadorActual, buscar,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Identificador>;
  }

  cambiarTipoModalidad(idParticipante: number, idAsamblea: number, idTipoModalidad: number) {
    return this.http.post(this.authService.url + `/participante/` + idParticipante + `/asamblea/` + idAsamblea + `/tipoModalidad/` + idTipoModalidad, null,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  cambiarTipoModalidadConf(idParticipante: number, idAsamblea: number, idTipoModalidad: number) {
    return this.http.post(this.authService.url + `/participante/` + idParticipante + `/asamblea/` + idAsamblea + `/tipoModalidad/` + idTipoModalidad + `/conf`, null,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

}
