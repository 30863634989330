import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'lista-asambleas',
    pathMatch: 'full'
  },
  {
    path: 'datos-generales',
    loadChildren: () => import('./pages/datos-generales/datos-generales.module').then( m => m.DatosGeneralesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convocatorias',
    loadChildren: () => import('./pages/convocatorias/convocatorias.module').then( m => m.ConvocatoriasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orden-dia',
    loadChildren: () => import('./pages/orden-dia/orden-dia.module').then( m => m.OrdenDiaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'participantes',
    loadChildren: () => import('./pages/participantes/participantes.module').then( m => m.ParticipantesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'votacion',
    loadChildren: () => import('./pages/votacion/votacion.module').then( m => m.VotacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'lista-asambleas',
    loadChildren: () => import('./pages/lista-asambleas/lista-asambleas.module').then( m => m.ListaAsambleasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'acciones-circulacion',
    loadChildren: () => import('./pages/acciones-circulacion/acciones-circulacion.module').then( m => m.AccionesCirculacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convocados',
    loadChildren: () => import('./pages/convocados/convocados.module').then( m => m.ConvocadosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'confirmados',
    loadChildren: () => import('./pages/confirmados/confirmados.module').then( m => m.ConfirmadosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'registrar-usuario',
    loadChildren: () => import('./pages/registrar-usuario/registrar-usuario.module').then( m => m.RegistrarUsuarioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-registro-acciones',
    loadChildren: () => import('./pages/libro-registro-acciones/libro-registro-acciones.module')
    .then( m => m.LibroRegistroAccionesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-deposito-acciones',
    loadChildren: () => import('./pages/libro-deposito-acciones/libro-deposito-acciones.module')
    .then( m => m.LibroDepositoAccionesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-error',
    loadChildren: () => import('./pages/core/modal-error/modal-error.module').then( m => m.ModalErrorPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-confirm',
    loadChildren: () => import('./pages/core/modal-confirm/modal-confirm.module').then( m => m.ModalConfirmPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recuperar-contrasena',
    loadChildren: () => import('./pages/recuperar-contrasena/recuperar-contrasena.module').then( m => m.RecuperarContrasenaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nueva-contrasena',
    loadChildren: () => import('./pages/nueva-contrasena/nueva-contrasena.module').then( m => m.NuevaContrasenaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'respuesta-solicitudes',
    loadChildren: () => import('./pages/respuesta-solicitudes/respuesta-solicitudes.module').then( m => m.RespuestaSolicitudesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'categoria-socios',
    loadChildren: () => import('./pages/categoria-socios/categoria-socios.module').then( m => m.CategoriaSociosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-registro-socios',
    loadChildren: () => import('./pages/libro-registro-socios/libro-registro-socios.module')
    .then( m => m.LibroRegistroSociosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-socios-inscriptos',
    loadChildren: () => import('./pages/libro-socios-inscriptos/libro-socios-inscriptos.module')
    .then( m => m.LibroSociosInscriptosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'categoria-socios-editar',
    loadChildren: () => import('./pages/categoria-socios-editar/categoria-socios-editar.module')
    .then( m => m.CategoriaSociosEditarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convocados-editar',
    loadChildren: () => import('./pages/convocados-editar/convocados-editar.module')
    .then( m => m.ConvocadosEditarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'votacion-editar',
    loadChildren: () => import('./pages/votacion-editar/votacion-editar.module')
    .then( m => m.VotacionEditarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'motivos-rechazo',
    loadChildren: () => import('./pages/motivos-rechazo/motivos-rechazo.module')
    .then( m => m.MotivosRechazoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'estructura-consorcio',
    loadChildren: () => import('./pages/estructura-consorcio/estructura-consorcio.module')
    .then( m => m.EstructuraConsorcioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'estructura-consorcio-editar',
    loadChildren: () => import('./pages/estructura-consorcio-editar/estructura-consorcio-editar.module')
    .then( m => m.EstructuraConsorcioEditarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convocados-masdatos',
    loadChildren: () => import('./pages/convocados-masdatos/convocados-masdatos.module')
    .then( m => m.ConvocadosMasdatosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-estructura-consorcio',
    loadChildren: () => import('./pages/libro-estructura-consorcio/libro-estructura-consorcio.module')
    .then( m => m.LibroEstructuraConsorcioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-propietarios-inscriptos',
    loadChildren: () => import('./pages/libro-propietarios-inscriptos/libro-propietarios-inscriptos.module').then( m => m.LibroPropietariosInscriptosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inscriptos-masdatos',
    loadChildren: () => import('./pages/inscriptos-masdatos/inscriptos-masdatos.module').then( m => m.InscriptosMasdatosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cuenta-admin',
    loadChildren: () => import('./pages/cuenta-admin/cuenta-admin.module').then( m => m.CuentaAdminPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detalle-liquidacion',
    loadChildren: () => import('./pages/detalle-liquidacion/detalle-liquidacion.module').then( m => m.DetalleLiquidacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forma-pago',
    loadChildren: () => import('./pages/forma-pago/forma-pago.module').then( m => m.FormaPagoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nueva-asamblea',
    loadChildren: () => import('./pages/nueva-asamblea/nueva-asamblea.module').then( m => m.NuevaAsambleaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-avanzada',
    loadChildren: () => import('./pages/conf-avanzada/conf-avanzada.module').then( m => m.ConfAvanzadaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-asamblea',
    loadChildren: () => import('./pages/conf-asamblea/conf-asamblea.module').then( m => m.ConfAsambleaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-participante',
    loadChildren: () => import('./pages/conf-participante/conf-participante.module').then( m => m.ConfParticipantePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-convocado',
    loadChildren: () => import('./pages/conf-convocado/conf-convocado.module').then( m => m.ConfConvocadoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'validacion-manual',
    loadChildren: () => import('./pages/validacion-manual/validacion-manual.module').then( m => m.ValidacionManualPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'validacion-lista',
    loadChildren: () => import('./pages/validacion-lista/validacion-lista.module').then( m => m.ValidacionListaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'validacion-editar-participante',
    loadChildren: () => import('./pages/validacion-editar-participante/validacion-editar-participante.module').then( m => m.ValidacionEditarParticipantePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./pages/image-modal/image-modal.module').then( m => m.ImageModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cuotapartes',
    loadChildren: () => import('./pages/cuotapartes/cuotapartes.module').then( m => m.CuotapartesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cambiar-clave-asamblear',
    loadChildren: () => import('./pages/cambiar-clave-asamblear/cambiar-clave-asamblear.module').then( m => m.CambiarClaveAsamblearPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cuotapartes-editar',
    loadChildren: () => import('./pages/cuotapartes-editar/cuotapartes-editar.module').then( m => m.CuotapartesEditarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-cuotapartes',
    loadChildren: () => import('./pages/libro-cuotapartes/libro-cuotapartes.module').then( m => m.LibroCuotapartesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'libro-cuotapartes-inscriptos',
    loadChildren: () => import('./pages/libro-cuotapartes-inscriptos/libro-cuotapartes-inscriptos.module').then( m => m.LibroCuotapartesInscriptosPageModule),
    canActivate: [AuthGuard]
  },  {
    path: 'orden-dia-editar',
    loadChildren: () => import('./pages/orden-dia-editar/orden-dia-editar.module').then( m => m.OrdenDiaEditarPageModule)
  },
  {
    path: 'cambiar-tipo-modalidad',
    loadChildren: () => import('./pages/cambiar-tipo-modalidad/cambiar-tipo-modalidad.module').then( m => m.CambiarTipoModalidadPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
