import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AuthService } from 'src/app/services/auth.service';
import { VersionService } from 'src/app/services/version.service';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;
const helper = new JwtHelperService();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: []
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public authService: AuthService,
    public usuarioService: UsuarioService,
    public versionService: VersionService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    console.log('--> appComponent esperando storage');
    this.platform.ready().then(async () => {
      this.statusBar.overlaysWebView(true);

      await Storage.get({key: this.authService.token_key}).then(respuesta => {

        const token = respuesta.value;
        if (token == null) {
          console.log('--> appComponent me voy por login');
          this.splashScreen.hide();
          this.router.navigate(['login']);
        } else {
          this.authService.token = token;
          this.authService.decodedToken = helper.decodeToken(token);

          /*const usuarioObservable = this.usuarioService.obtenerUsuario();
          usuarioObservable.subscribe( data => {
            this.authService.usuario = data;
          },
          (err) => {
            this.splashScreen.hide();
            this.router.navigate(['login']);
          });*/
        }
      });
    });
  }
}
