import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { OrdenDia } from '../models/ordenDia';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdenDiaService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  grabarOrdenDia(idAsamblea: number, ordenDia: OrdenDia): Observable<OrdenDia> {
    return this.http.post(this.authService.url + `/asamblea/temario/` + idAsamblea, ordenDia,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<OrdenDia>;
  }

  obtenerOrdenDia(idAsamblea: number): Observable<OrdenDia[]> {
    return this.http.get(this.authService.url + `/asamblea/temario/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<OrdenDia[]>;
  }

  eliminarOrdenDia(idOrdenDia: number) {
    return this.http.delete(this.authService.url + `/asamblea/temario/` + idOrdenDia,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }


}
