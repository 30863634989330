import { Directive } from '@angular/core';

@Directive({
  selector: '[appMask]'
})
export class MaskDirective {

  constructor() { }

}
