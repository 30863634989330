import { Component, OnInit, Input } from '@angular/core';
import { AsambleaService } from 'src/app/services/asamblea.service';
import { CategoriaSocioService } from '../../services/categoria-socio.service';
import { CategoriaSocio } from 'src/app/models/categoria-socio';
import { AlertController, ModalController } from '@ionic/angular';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ModalErrorPage } from '../core/modal-error/modal-error.page';

@Component({
  selector: 'app-categoria-socios-editar',
  templateUrl: './categoria-socios-editar.page.html',
  styleUrls: ['./categoria-socios-editar.page.scss'],
})
export class CategoriaSociosEditarPage implements OnInit {

  @Input() categoriaSocio: CategoriaSocio;

  private currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 12,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });


  categoria: string;
  categoriaInvalido: boolean;
  cantidad: number;
  cantidadInvalido: boolean;
  votos: number;
  votosInvalido: boolean;


  constructor(private asambleaService: AsambleaService, private categoriaSocioService: CategoriaSocioService,
              private alertController: AlertController, private modalCtrl: ModalController) { }

  ngOnInit() {
    this.categoria = this.categoriaSocio.categoria;
    this.votos = this.categoriaSocio.votos;
    this.cantidad = this.categoriaSocio.cantidad;
  }

  confValor(nombre: string) {
    return this.asambleaService.getConfiguracionValue(nombre)?.valor;
  }

  grabaCategoriaSocio() {

    if (!this.validarAccion()) {
      console.log('error valida');
      return;
    }

    const categoriaSocio = new CategoriaSocio();

    categoriaSocio.id = this.categoriaSocio.id;
    categoriaSocio.categoria = this.categoria;
    categoriaSocio.cantidad = this.cantidad;
    categoriaSocio.votos = this.votos;

    this.categoriaSocioService.grabarCategoriaSocio(this.asambleaService.asamblea.id, categoriaSocio).subscribe(
      async (accion) => {
        this.categoria = null;
        this.cantidad = null;
        this.votos = null;
        this.modalCtrl.dismiss();
      },
      async (error) => {
        console.error(error);
        this.modalCtrl.dismiss();
      }
    );

  }


  eliminarCategoriaSocio(idCategoriaSocio: number) {
    this.categoriaSocioService.eliminarCategoriaSocio(idCategoriaSocio).subscribe(
      async (data) => {
        await this.presentAlert('Categoria Socio', 'Se eliminó la categoria');
      },
      async (error) => {
        console.error(error);
      }
    );
  }

  async presentAlert(subHeader: string, message: string) {
    const alert = await this.alertController.create({
      header: 'Atencion',
      subHeader,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  validarAccion() {
    this.categoriaInvalido = ((this.categoria == null) || (this.categoria == ''));
    this.votosInvalido = (this.votos == null);
    this.cantidadInvalido = (this.cantidad == null);

    return !(this.cantidadInvalido || this.votosInvalido || this.categoriaInvalido);
  }

  cancelar() {
    this.modalCtrl.dismiss();
  }

}
