import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Votacion } from '../models/votacion';
import { BaseCalculo } from '../models/base-calculo';
import { OpcionVotacion } from '../models/opcion-votacion';

@Injectable({
  providedIn: 'root'
})
export class VotacionService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  grabarVotacion(idAsamblea: number, votacion: Votacion): Observable<Votacion> {
    return this.http.post(this.authService.url + `/votacion/` + idAsamblea + `/asamblea/`, votacion,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Votacion>;
  }

  obtenerVotacion(idAsamblea: number) {
    return this.http.get(this.authService.url + `/votacion/` + idAsamblea + `/asamblea/`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Array<Votacion>>;
  }

  eliminarVotacion(idVotacion: number) {
    return this.http.delete(this.authService.url + `/votacion/` + idVotacion,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  eliminarOpcionVotacion(idOpcion: number) {
    return this.http.delete(this.authService.url + `/votacion/opcionVotacion/` + idOpcion,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  agregarOpcionVotacion(idVotacion: number, opcion: OpcionVotacion): Observable<Votacion> {
    return this.http.post(this.authService.url + `/votacion/` + idVotacion + `/agregarOpcion`, opcion,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Votacion>;
  }

  baseCalculo(): Observable<BaseCalculo[]> {
    return this.http.get(this.authService.url + `/baseCalculo/lista/`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as  Observable<BaseCalculo[]>;
  }



}
