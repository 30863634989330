import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Convocado } from 'src/app/models/convocado';
import { AsambleaService } from 'src/app/services/asamblea.service';

@Component({
  selector: 'app-convocados-masdatos',
  templateUrl: './convocados-masdatos.page.html',
  styleUrls: ['./convocados-masdatos.page.scss'],
})
export class ConvocadosMasdatosPage implements OnInit {

  @Input() convocado: Convocado;

  constructor(private modalCtrl: ModalController, private asambleaService: AsambleaService) { }

  ngOnInit() {
  }

  cancelar() {
    this.modalCtrl.dismiss();
  }

  confValor(nombre: string) {
    return this.asambleaService.getConfiguracionValue(nombre)?.valor;
  }
}
