import { AccionConvocado } from 'src/app/models/accion-convocado';
import { CategoriaSocio } from './categoria-socio';
import { EstructuraUnidades } from './estructura-unidades';
import { Participante } from 'src/app/models/participante';
import { Documentacion } from './documentacion';
import { ConvocadoCuotaparte } from './convocado-cuotaparte';
export class Convocado {
    id: number;
    idTipoDocumento: number;
    tipoDocumento: string;
    numero: string;
    nombre: string;
    domicilio: string;
    email: string;
    idPerfil: number;
    perfil: string;
    participanteDTO: Participante;
/*  idParticipante: number;
    participante: string;
    participanteDocumento: string;*/
    idEstado: number;
    fechaEstado: Date;
    fechaInscripcion: Date;
    estado: string;
    descripcionEstado: string;
    moderador: boolean;
    orador: boolean;
    categoriaSocioDTO: CategoriaSocio;
    accionesDTO: Array<AccionConvocado>;
    estructuraUnidadesDTO: Array<EstructuraUnidades>;
    cuotapartesDTO: Array<ConvocadoCuotaparte>;
    orden: number;
    eliminar: boolean;
    presenteHora: string;

    idAsambleaParticipante: number;
    presente: boolean;
    sumaQuorum: boolean;
    perteneceAsociacion: boolean;
    tieneRepresentante: boolean;

    afipVerificado: boolean;
    dniVerificado: boolean;
    mailVerificado: boolean;

    coeficiente: number;
    capital: number;
    votos: number;

    voz: boolean;
    chat: boolean;
    voto: boolean;
    sonidoVideo: boolean;
    documentacion: boolean;
    personeria: boolean;
    idPerfilNuevo: number;

    noVotoCantidadOpciones: number;
    noVotoMotivoDescripcion: string;
    noVotoMotivoId: number;
    noVotoTotalCoeficiente: number;
    noVotoTotalVotos: number;
    pais: string;
    participante: string;
    participanteDocumento: string;
    representado: boolean;
    tipoModalidad: string;
    tipoModalidadId: number;
    tipoParticipacion: string;
    tipoParticipacionId: number;


}


