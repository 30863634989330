import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MaskDirective } from './directives/mask.directive';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { AsambleaService } from './services/asamblea.service';
import { TextMaskModule } from 'angular2-text-mask';
import es from '@angular/common/locales/es';
import { IonicSelectableModule } from 'ionic-selectable';
import { registerLocaleData } from '@angular/common';
import { CategoriaSociosEditarPage } from './pages/categoria-socios-editar/categoria-socios-editar.page';
import { ConvocadosEditarPage } from './pages/convocados-editar/convocados-editar.page';
import { VotacionEditarPage } from './pages/votacion-editar/votacion-editar.page';
import { ConvocadosMasdatosPage } from './pages/convocados-masdatos/convocados-masdatos.page';
import { ValidacionEditarParticipantePage } from './pages/validacion-editar-participante/validacion-editar-participante.page';
import { ImageModalPage } from './pages/image-modal/image-modal.page';

registerLocaleData(es);

export function jwtOptionsFactory(authService) {
  return {
    tokenGetter: () => {
      return authService.token;
    },
    allowedDomains: ['localhost:8100']
  };
}
const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    MaskDirective,
    CategoriaSociosEditarPage,
    VotacionEditarPage,
    ConvocadosEditarPage,
    ConvocadosMasdatosPage,
    ValidacionEditarParticipantePage,
    ImageModalPage
    ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(maskConfig),
    TextMaskModule,
    IonicSelectableModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS  ,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      }
    }),
  ],
  providers: [
    DatePipe,
    StatusBar,
    FormBuilder,
    SplashScreen,
    AuthService,
    TextMaskModule,
    AsambleaService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es-AR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
