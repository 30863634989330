export class AccionCirculacion {
    id: number;
    fecha: Date;
    idClase: number;
    clase: number;
    serie: string;
    cantidad: number;
    valor: number;
    votos: number;
    claseSerie: string;
}
