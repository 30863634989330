import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { VersionControl } from '../models/versionControl';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private http: HttpClient, private authService: AuthService, private platform: Platform) { }

  version: string;
  url: string;
  plataforma: string;

  public getVersion(): string {
    return '1.0.9';
  }

  checkVersion(): Observable<VersionControl> {

    this.version = this.getVersion();

    return this.http.post(environment.url + '/rest/checkVersion.json', this.version,
                      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
                      ) as Observable<VersionControl>;
  }

  public getPlatform() {
    if (this.platform.is('ios')) {
      return 'ios';
    }
    if (this.platform.is('android')) {
      return 'android';
    }
    return 'other';

  }


}
