import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { Token } from '../models/token';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { AuthService } from './auth.service';
import { ValidarUsuario } from '../models/validar-usuario';
import { CambiarClave } from '../models/cambiar-clave';
import { Participante } from '../models/participante';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient, private authService: AuthService, private platform: Platform) { }

  login(credentials) : Observable<Token> {
    return <Observable<Token>> this.http.post(this.authService.url + `/usuarios/login/administrador`, credentials, 
                              { headers: {'Content-Type': 'application/json'} }
                      );
  }
 
  crearCuenta(datos): Observable<Usuario> {
    return <Observable<Usuario>> this.http.post(this.authService.url + `/usuarios`, datos, 
                              { headers: {'Content-Type': 'application/json'} }
                      );        
  }

  public logout() {
    this.platform.ready().then(async () => {
      await
        Storage.remove({key: this.authService.token_key}).then(() => {
        this.authService.usuario = null;
      });
    });
  }

  validarManualUsuario(idTipoDocumento: number, validarUsuario: ValidarUsuario): Observable<Usuario> {
    return this.http.post(this.authService.url + `/usuarios/manual/tipoDocumento/` + idTipoDocumento, validarUsuario,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Usuario>;
  }

  cambiarClaveAsamblear(cambiarClave: CambiarClave) {
    return this.http.post(this.authService.url + `/usuario/perfil/cambiarClave/asamblear`, cambiarClave,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  urlValidacion(email: string): Observable<Participante> {

    const formData = new FormData();
    formData.append('email', email);

    return this.http.post(this.authService.url + `/usuario/urlValidacion`, formData,
        { headers: {enctype: 'multipart/form-data; boundary=WebAppBoundary',
                    Authorization: 'Bearer ' + this.authService.token}}
    ) as Observable<Participante>;
  }

}