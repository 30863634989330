import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { CategoriaSocio } from '../models/categoria-socio';
import { Observable } from 'rxjs';
import { EstructuraUnidades } from '../models/estructura-unidades';
import { Buscar } from 'src/app/models/buscar';
import { PaginaEstructuraUnidad } from '../models/pagina-estructura-unidad';

@Injectable({
  providedIn: 'root'
})
export class EstructuraUnidadService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  grabarEstructuraUnidad(idAsamblea: number, estructuraUnidad: EstructuraUnidades): Observable<EstructuraUnidades> {
    return this.http.post(this.authService.url + `/estructuraUnidad/asamblea/` + idAsamblea, estructuraUnidad,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<EstructuraUnidades>;
  }

  obtenerEstructuraUnidad(idAsamblea: number): Observable<EstructuraUnidades[]> {
    return this.http.get(this.authService.url + `/estructuraUnidad/asamblea/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<EstructuraUnidades[]>;
  }

  obtenerEstructuraUnidadPaginado(idAsamblea: number, pagina: number, buscar: Buscar): Observable<PaginaEstructuraUnidad> {
    return this.http.post(this.authService.url + `/estructuraUnidad/asamblea/` + idAsamblea + '/pagina/' + pagina, buscar,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaEstructuraUnidad>;
  }

  eliminarEstructuraUnidad(idEstructuraUnidad: number) {
    return this.http.delete(this.authService.url + `/estructuraUnidad/id/` + idEstructuraUnidad,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  buscarEstructuraUnidad(idAsamblea: number, pagina: number, buscar: Buscar): Observable<PaginaEstructuraUnidad> {
    return this.http.post(this.authService.url + `/estructuraUnidad/asamblea/` + idAsamblea + `/pagina/` + pagina, buscar,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaEstructuraUnidad>;
  }

}
