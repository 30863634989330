import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Asamblea } from '../models/asamblea';
import { AuthService } from 'src/app/services/auth.service';
import { TerminosCondiciones } from '../models/terminos-condiciones';
import { Configuracion } from '../models/configuracion';
import { CrearAsamblea } from '../models/crear-asamblea';
import { RolAsamblea } from '../models/rol-asamblea';
import { ParticipanteRol } from 'src/app/models/participante-rol';
import { PaginaAsamblea } from '../models/pagina-asamblea';
import { RolAsambleaParticipante } from '../models/rol-asamblea-participante';
import { Quorum } from '../models/quorum';

@Injectable({
  providedIn: 'root'
})
export class AsambleaService {

  public asamblea: Asamblea;
  public configuracion: Array<Configuracion> = [];

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.getToken();
  }

  nuevaAsamblea(nuevaAsamblea: CrearAsamblea) {
    return this.http.post(this.authService.url + `/public/cliente/nuevaAsamblea/`, nuevaAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
    
  }

  grabarAsamblea(idAsamblea: number, asamblea: Asamblea): Observable<Asamblea> {
    return this.http.post(this.authService.url + `/asamblea/` + idAsamblea, asamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Asamblea>;
  }

  obtenerAsamblea(idAsamblea: number) {
    return this.http.get(this.authService.url + `/asamblea/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Asamblea>;
  }

  obtenerRolesAsamblea(): Observable<RolAsamblea[]> {
    return this.http.get(this.authService.url + `/rolesAsamblea/lista`,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<RolAsamblea[]>;
  }

  grabarTerminosCondiciones(idAsamblea: number, terminosCondiciones: TerminosCondiciones): Observable<TerminosCondiciones> {
    return this.http.post(this.authService.url + `/asamblea/terminosycondiciones/` + idAsamblea, terminosCondiciones,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TerminosCondiciones>;
  }

  obtenerTerminosCondiciones(idAsamblea: number) {
    return this.http.get(this.authService.url + `/asamblea/terminosycondiciones/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<TerminosCondiciones>;
  }

  grabarActa(idAsamblea: number, asamblea: Asamblea): Observable<Asamblea> {
    return this.http.post(this.authService.url + `/asamblea/` + idAsamblea + `/acta`, asamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Asamblea>;
  }

  minutaPreasamblearia(idAsamblea: number) {
    return this.http.get(this.authService.url + `/listado/minutaPreasamblearia/` + idAsamblea,
        {headers: {'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + this.authService.token},
        responseType: 'blob'}
    );
  }

  asignarRolParticipante(participanteRol: ParticipanteRol) {

    return this.http.post(this.authService.url + `/public/participante/rol`, participanteRol,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  paginaAsamblea(pagina: number, idColumna: number, idOrden: number): Observable<PaginaAsamblea> {

    /*
      idColumna
      1 - Fecha cierre inscripción asamblea
      2 - Código Cliente
      3 - Nombre Cliente
      
      idOrden
      1 - Ascendente
      2 - Descendente
    */

    return this.http.get(this.authService.url + `/asamblea/pagina/` + pagina + `/columna/` + idColumna + `/orden/` + idOrden,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<PaginaAsamblea>;
  }
 
  resetMail(idAsamblea: number) {

    return this.http.post(this.authService.url + `/asamblea/resetMail/` + idAsamblea, null,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  getConfiguracion(app: number, idioma: number, idTipoReunion: number): Array<Configuracion> {

    return this.configuracion;

  }

  addItem(id: number, app: number, idioma: number, nombre: string, tipo: number, valor: any) {
    const item = new Configuracion();

    item.id = id;
    item.app = app;
    item.idioma = idioma;
    item.tipo = tipo;
    item.nombre = nombre;
    item.valor = valor;

    return item;

  }

  getConfiguracionValue(nombre: string) {

    if (this.configuracion == null) {
      return;
    }
    const conf = this.configuracion.filter(conf => conf.nombre == nombre)[0];

    if (conf?.tipo == 3) {
      conf.valor = (conf.valor > 0);
    }

    return conf;
  }

  actualizarCambioConfiguracion(idAsamblea: number): Observable<Asamblea> {
    return this.http.get(this.authService.url + `/asamblea/cambioConfiguracion/` + idAsamblea,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Asamblea>; 
  }

  checkStreaming(asamblea: Asamblea): Observable<any> {

/*    let server: string;
    if (asamblea.servidorMeet.includes('meet-01')) {
      server = 'meet-01';
    } else {
      server = 'meet';
    }*/
    return this.http.get(asamblea.jitsiServerUrlStreaming,
        { headers: {'Content-Type': 'application/json'}}) as Observable<any>;
  }
    

  startJitsi(idAsamblea: number): Observable<Asamblea> {
    return this.http.get(this.authService.url + `/asamblea/` + idAsamblea + `/prenderJitsi`,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Asamblea>; 
  }

  stopJitsi(idAsamblea: number): Observable<Asamblea> {
    return this.http.get(this.authService.url + `/asamblea/` + idAsamblea + `/apagarJitsi`,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Asamblea>; 
  }

  habilitarCamaras(idAsamblea: number) {
    return this.http.post(this.authService.url + `/asamblea/` + idAsamblea + `/habilitarCamaras/`, null,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  deshabilitarCamaras(idAsamblea: number) {
    return this.http.post(this.authService.url + `/asamblea/` + idAsamblea + `/deshabilitarCamaras/`, null,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

  rolesAsamblea(idAsamblea: number): Observable<RolAsambleaParticipante[]> {
    return this.http.get(this.authService.url + `/asamblea/` + idAsamblea + `/rolAsambleaParticipante`,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<RolAsambleaParticipante[]>; 
  }

  quorum(idAsamblea: number): Observable<Quorum> {
    return this.http.get(this.authService.url + `/quorum/asamblea/` + idAsamblea,
      { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<Quorum>; 
  }


}
