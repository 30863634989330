import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { CategoriaSocio } from '../models/categoria-socio';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriaSocioService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  grabarCategoriaSocio(idAsamblea: number, categoriaSocio: CategoriaSocio): Observable<CategoriaSocio> {
    return this.http.post(this.authService.url + `/categoriaSocio/asamblea/` + idAsamblea, categoriaSocio,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<CategoriaSocio>;
  }

  obtenerCategoriaSocio(idAsamblea: number): Observable<CategoriaSocio[]> {
    return this.http.get(this.authService.url + `/categoriaSocio/asamblea/` + idAsamblea,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    ) as Observable<CategoriaSocio[]>;
  }

  eliminarCategoriaSocio(idCategoriaSocio: number) {
    return this.http.delete(this.authService.url + `/categoriaSocio/categoria/` + idCategoriaSocio,
        { headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.authService.token} }
    );
  }

}
